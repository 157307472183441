const idPortalApi = "b4d2b3606f9f35f42e191dcbb98a00819cb5ed32";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmpresidentemedici.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmpresidentemedici.ma.gov.br",
  NomePortal: "Presidente Médici",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 6, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/iBYgiJSD8ZNn2wU26`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d135966.37891339042!2d-45.87165553649112!3d-2.3346853112867096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92b3b04a9a33d5cf%3A0xe86cb152a4f82108!2sPresidente%20M%C3%A9dici%2C%20Maranh%C3%A3o%20-%20State%20of%20Maranh%C3%A3o!5e0!3m2!1sen!2sbr!4v1738152709492!5m2!1sen!2sbr",
};
